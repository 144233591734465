.widget {
    background: transparent;
    overflow: hidden;
    height: 600px;
    min-height: 600px;
    max-height: 600px;

    main {
        padding: 20px;
        background: linear-gradient(112deg, rgba(239,243,246,1) 0%, rgba(211,214,219,1) 100%);
        border-radius: 8px;
        border: 1px solid #D3D6DB;
    }
    h2 {
        margin: 17px 0 0;
        padding: 0 20px;
        text-align: center;
    }
    a {
        text-decoration: none;
    }
    .head {
        display: flex;
        justify-content: space-between;

        @include mobile-only {
            flex-wrap: wrap;
            h2 {
                order: 2;
                width: 100%;
                margin-top: 10px;
            }
            .pc-logo {
                width: 60px;
            }
        }
    }
    .pc-logo {
        width: 100px;
    }
    .afp-logo {
        width: 110px;
    }
    .legend {
        padding: 0 0 20px;
    }
    .line-chart-wrap {
        padding: 10px 0;
        max-height: 320px;
        .canvas-wrap {
            height: 230px;
            @include mobile-only {
                height: 230px;
            }   
        }
    }
    .source {
        text-align: right;
        font-size: 12px;
    }
    .powered-by {
        font-size: 10px;
        text-decoration: none;
        padding-bottom: 6px;
        text-align: center;
        color: $primary;
        margin-top: -5px;
    }

    .concern-scroller {
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
        width: calc(100vw - 40px);
        padding: 10px 0;
        border-top: 1px solid $med-grey;
        margin: 10px 0 14px;
        position: relative;
  
        

        &::-webkit-scrollbar {
            height: 7px;
        }
    
        /* button */
        &::-webkit-scrollbar-button {
            background: transparent;
            width: 2px;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $med-grey;
            border: 1px solid transparent;
            border-radius: 3px;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #666;
        }
        &::-webkit-scrollbar-corner {
            background: #999;
        }
    
        /* Resizer */
        &::-webkit-resizer {
            background: #111;
        }
        .bar {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            padding: 10px 0;
            position: relative;
            @include mobile-only {
                padding: 0;
            }
        }
        li {
            border: 1px solid #f9fafb;
            background: #f3f5f7;
            margin: 0 10px;
            border-radius: 6px;
            padding: 15px;
            min-width: 240px;
            display: flex;
            font-size: 14px;
            align-items: center;
            cursor: pointer;
            .icon {
                font-size: 30px;
                margin-right: 10px;
                pointer-events: none;
            }
            &.active {
                color: $primary;
                border-color: $primary;
                background: white;
            }
        }
    }
    .toolbar {
        display: flex;
        position: relative;
        &:after {
             content: "";
             background: $med-grey;
             height: 1px;
             width: calc(100vw - 43px);
             position: absolute;
             bottom: 10px;
        }
        // &:after {
        //     content: "";
        //     background: red;
        //     position: absolute;
        //     top: 0;
        //     left: -10px;
        //     width: 10px;
        //     height: 90px;
        //     box-shadow: calc(100vw - 30px) 0 23px rgba(0,0,0,0.7);
        //     border-radius: 50%;
        // }
    }
    .end {
        background: darken(#A0A4AA, 15%);
        width: 1px;

    }
}