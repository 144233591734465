$primary: #037F8B;
$primary-highlight: #69A5AF; //#BB8CF2;
$primary-dark: #006D77;
$teal-2: #45919C;
$teal-3: #69A5AF;
$teal-4: #8ABDC4;
$teal-5: #ADDADE;
$secondary: rgb(93, 0, 165);
$tertiary: #E85642;
$light-grey: #D3D6DB;
$chat-grey: #EFF3F6;
$avatar-bg: #E1E5EB;
$med-grey: #A0A4AA;
$dark-grey: #666;
$black: #333;

$party-labor: #de3533;
$party-liberal: #0047ab;
$party-nationals: #38761d;
$party-greens: #39b54a;
$party-independents: #b2b4b7;
$party-ca: #ff7f01;
$party-kap: #7f6000;

$br: 8px;
$br-small: 3px;
$br-chat: 8px;
$mw: 1400px;
$mw-med: 1200px;
$mw-thin: 800px;
$transition: .3s ease-out;
$transition-chat: .7s ease-out;
$tablet-width: 768px;
$tablet-landscape-width: 1024px;
$desktop-width: 1280px;
$large-desktop-width: 1600px;