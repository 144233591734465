.sticky-bottom {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	height: 265px;
	transition: $transition-chat;
	z-index: 999;

	@include tablet {
		left: 0;
		right: 0;
	}

	&.large {
		height: 50vh;
	}
	&.minimised {
		height: 37px;
	}
}
.three-quarter .sticky-bottom {
	height: 75vh;
	.chat-window {
		height: calc(75vh - 40px);
	}
}

.intro-card {
	max-width: 700px;
	background: $primary;
	font-size: 16px;
	
	margin: 0;
	color: white;
	text-align: center;
	padding: 15px 20px;
	position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
	transition: $transition-chat;
	

	@include tablet {
		border-radius: $br;
		margin: 0 auto 50px;
		padding: 25px 100px;
		transform: translateY(130%);
	animation: 0.5s 1.25s slideUp forwards;
	}
	&.hide {
		top: 100%;
	}

	.btn {
		display: inline-block;
		border: 1px solid white;
		border-radius: $br;
		text-transform: uppercase;
		line-height: 1;
		padding: 12px 35px 0;
		margin-top: 10px;
		cursor: pointer;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 0.1em;
		transition: .3s ease-out;

		&:hover {
			background: white;
			color: $primary;
		}

		> * { pointer-events: none; }
	}
	h1 {
		font-size: 22px;
		font-weight: 500;
		margin: 0 0 10px;
		@include tablet {
			font-size: 26px;
		}
	}
	p {
		margin: 0 0 10px;
		font-size: 16px;
		font-weight: 300;
	}
}

.chat-tab {
	background: $primary;
	max-width: $mw-med;
	margin: 0 auto;
	
	//margin-bottom: env(safe-area-inset-bottom);

	color: white;
	border-radius: $br $br 0 0;
	position: relative;
	z-index: 99;
	padding: 10px 20px;
	letter-spacing: .025em;
	font-weight: 600;
	font-size: 14px;
	top: 1400px;
	transition: $transition-chat;
	cursor: pointer;

	.sticky-bottom.minimised & {
		top: 0;
	}
	&:after {
		content: '\002B';
		float: right;
		font-size: 20px;
		line-height: 1;
	}
}
.chat-window {
	background: white;
	max-width: $mw-med;
	margin: 0 auto;
	box-shadow: 0 5px 3px rgba(0,0,0,0.16);
	border-top: 10px solid white;
	padding: 10px;
	position: absolute;
	top: 50vh;
	height: 50vh;
	transition: $transition-chat;
	left: 0;
	right: 0;
	overflow-y: scroll;
//	-webkit-overflow-scrolling: touch;
	scroll-behavior: smooth;
	/* width */

	@include tablet {
		border: none;
		border-radius: $br;
		box-shadow: 0 3px 1px rgba(0,0,0,0.16);
		padding: 20px 25px;
		margin: 0 10px 10px;
		height: calc( 50vh - 10px);
	}
	@include desktop {
		margin: 0 auto 40px;
		padding: 40px 50px;
		height: calc( 50vh - 40px)
	}

	&::-webkit-scrollbar {
		width: 7px;
	}

	/* button */
	&::-webkit-scrollbar-button {
		background: red;
		height: 0;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #ccc;
		border-radius: 3px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #666;
	}

	/* Track */
	// &::-webkit-scrollbar-track {
	//     background: #000; 
	// }

	/* The track NOT covered by the handle. */
	// &::-webkit-scrollbar-track-piece {
	//     background: #000; 
	// }

	/* Corner */
	&::-webkit-scrollbar-corner {
		background: #999;
	}

	/* Resizer */
	&::-webkit-resizer {
		background: #111;
	}

	&.open {
		top: 0;
	}
}

.launch-compare {
	color: white;
	background: $primary;
	&:hover {
		background: lighten($primary, 10%);
	}
	&:active {
		background: darken($primary, 10%);
	}
}

