.dev {
    display: none;
    position: absolute;
    left: 10px;
    width: 700px;
    padding: 10px;
    border: 1px solid #aaa;
    border-radius: 4px;
	background: #f0f0f0;
	z-index: 100;
    .dev-form {
        display: flex;
    }
    h2 {
        margin: 0 0 5px;
    }
    input {
        padding: 5px 10px;
        border-radius: 2px;
        border: 1px solid #bbb;
        width: calc(100% - 55px);
    }
    .dev-submit {
        padding: 10px;
    }
    .dev-output {
        margin-top: 10px;
        padding: 10px;
        min-height: 25px;
        border: 1px solid #bbb;
        width: 100%;
        display: block;
        max-height: 400px;
        overflow-y: scroll;
        background: white;
    }
    .sb {
        border-radius: 8px;
        color: $black;
        border: 1px solid #D3D6DB;
        background: white;
        margin: 0 4px 4px 0;
        padding: 10px 25px;
        display: inline-block;
        text-align: center;
        .type {
            font-size: 10px;
            display: block;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }
        .name {
            font-size: 16px;
            display: block;
        }

    }
    


}