.ie11, .ie10 {
	.ie-warning {
		display: block;
		width: 100%;
		border-top: 4px solid $primary;
		border-bottom: 4px solid $primary;
		padding: 100px;
		background: white;
		color: $black;
		font-size: 16px;
		position: fixed;
		left: 0;
		top: 50%;
		margin-top: -150px;
		z-index: 999999;
		p {
			margin: 0 auto 5px;
			max-width: 500px;
		}
	}
}