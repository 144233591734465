.chat-window {
	.sb {
		border-radius: $br-chat;
		padding: 7px 15px;
		margin: 2px 0;
		display: inline-block;

		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.025em;

		@include tablet {
			padding: 13px 25px 12px;
			// font-size: 14px;
			// letter-spacing: 0.1em;
			// font-weight: bold;
		}

		p {
			margin: 0 0 5px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.pc {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.sb {
			background: $chat-grey;
			color: $black;
			border-radius: 2px $br-chat $br-chat 2px;
			animation: .1s appearHoz;
			transform-origin: bottom;

			&:first-child {
				border-top-left-radius: $br-chat;
			}

			&:last-child {
				border-bottom-left-radius: $br-chat;
			}
		}

		strong {
			color: $primary;
		}
	}

	.row {
		display: flex;
		justify-content: flex-end;
	}

	.options {
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		margin: 10px 0;

		@include tablet {
			flex-direction: row;
		}

	}

	.input {
		margin: 10px 0;
	}

	.option {
		background: $primary;
		color: white;
		margin: 0 0 5px 4px;
		cursor: pointer;
		transition: $transition;
		text-align: center;

		@include tablet {

			margin-bottom: 0;
			// text-transform: uppercase;
			// font-size: 12px;
			// font-weight: bold;
			// letter-spacing: 0.1em;
		}

		@include mobile-only {
			margin: 0 0 2px;
			padding: 15px;
		}

		&:first-child {
			margin-left: 0;
		}

		&:hover {
			&:hover {
				background: $primary-highlight;
			}

			&:active {
				background: darken($primary, 10%);
			}
		}

		@for $i from 1 through 4 {
			&:nth-last-child(#{$i}) {
				opacity: 0;
				animation: .3s fadeIn forwards ($i * .1s);
			}
		}
	}

	.response {
		background: white;
		border: 1px solid $light-grey;
		float: right;
		margin: 10px 0;
		animation: .3s appearVert forwards;
		transform-origin: right;
		color: $black;
		cursor: default;

		&:hover {
			color: $black;
			background: white;
		}

		@include tablet {
			// font-size: 12px;
			// text-transform: uppercase;
		}
	}

	.loading {
		background: $light-grey;
		will-change: transform;
		width: auto;
		display: table;
		clear: both;
		border-radius: $br-chat;
		padding: 13px 15px;
		// animation: .1s appear;
		// 	transform-origin: bottom;

		span {
			height: 5px;
			width: 5px;
			float: left;
			margin: 0 1px;
			background-color: #9E9EA1;
			display: block;
			border-radius: 50%;
			opacity: 0.4;

			@for $i from 1 through 3 {
				&:nth-of-type(#{$i}) {
					animation: 1s blink infinite ($i * .3333s);
				}
			}
		}
	}

	.electorate-input, .email-input {
		border-radius: $br;
		border: 1px solid $light-grey;
		display: flex;
		justify-content: space-between;
		padding: 5px;
	}

	input[type="text"], input[type="email"] {
		border: none;
		border-radius: $br+2px;
		background: white;
		padding: 4px 10px;
		font-size: 16px;
		width: calc(100% - 67px);
		outline: none;

		@include tablet {
			padding: 4px 25px;
		}
	}

	.send {
		color: white;
		background: $primary;

		height: 32px;
		border-radius: $br-small;
		padding: 7px 16px;
		letter-spacing: 0.025em;
		// font-weight: bold;
		font-size: 14px;

	}

	.zones {
		text-align: right;
		margin: 10px 0;
	}

	.pick-zone {
		border-radius: 8px;
		//color: $black;
		//border: 1px solid #D3D6DB;
		//background: white;
		color: white;
		background: $primary;
		margin: 0 4px 4px 0;
		padding: 7px 13px;
		display: inline-block;
		text-align: center;
		font-weight: normal;
		cursor: pointer;
		transition: $transition;

		&.selected {
			color: $black;
			border: 1px solid #D3D6DB;
			background: white;
			cursor: default;

			&:hover {
				background: white;
			}
		}

		@include tablet {
			padding: 10px 25px;
		}

		@for $i from 1 through 19 {
			&:nth-last-child(#{$i}) {
				opacity: 0;
				animation: .3s fadeIn forwards ($i * .1s);
			}
		}

		&:hover {
			background: $primary-highlight;
		}

		&:active {
			background: darken($primary, 10%);
		}

		>* {
			pointer-events: none;
		}

		.type {
			font-size: 9px;
			display: block;
			letter-spacing: 0.1em;
			text-transform: uppercase;

			@include tablet {
				font-size: 10px;
				font-size: 10px;
			}
		}

		.name {
			font-size: 14px;
			display: block;
			letter-spacing: 0;

			@include tablet {
				font-size: 16px;
			}
		}
	}
}