@keyframes chartjs-render-animation {
    from {
        opacity: .99
    }

    to {
        opacity: 1
    }
}

.chartjs-render-monitor {
    animation: chartjs-render-animation 1ms
}

.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
    position: absolute;
    direction: ltr;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    z-index: -1
}

.chartjs-size-monitor-expand>div {
    position: absolute;
    width: 1000000px;
    height: 1000000px;
    left: 0;
    top: 0
}

.chartjs-size-monitor-shrink>div {
    position: absolute;
    width: 200%;
    height: 200%;
    left: 0;
    top: 0
}

.line-chart-wrap {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 0;
    min-height: 200px;

    @include tablet {
        padding: 50px 0;
    }
}



.charts {
    background: $chat-grey;

    h2 {
        margin: 20px 0 0;
        font-weight: 600;
        font-size: 22px;

        @include tablet {
            font-size: 34px;
            letter-spacing: 0.0125em;
        }

    }

    .subheading {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 600;

        @include tablet {}
    }
    .source {
        text-align: right;
        font-size: 14px;
    }
}

.national-map {
    max-width: $mw;
    padding: 40px;
    margin: 0 auto;
    display: none;

    @include tablet {
        display: block;
        height: 80vh;
    }

    svg {
        max-height: 100%
    }

    .svg-electorate {
        cursor: pointer;

        &.active {
            fill: white;
            stroke: $primary;
            stroke-width: 2px;
        }
    }
}

.popup {
    position: absolute;
    background: white;

    border: 1px solid $primary;
    border-radius: $br;

    text-align: center;
    padding: 20px 30px;
    display: none;

    @include tablet {
        border-color: $light-grey;
        box-shadow: 0 5px 3px rgba(0, 0, 0, 0.16);
        width: 350px;
    }

    &.active {
        display: block;
    }

    &.inline {
        position: relative;
        width: 100%;
        margin-top: 20px;
    }

    .close {
        color: $light-grey;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        display: block;
        cursor: pointer;
        font-size: 40px;
        line-height: 20px;
        text-align: right;
    }

    h3 {
        font-size: 32px;
        font-weight: 600;
        margin: 0 0 20px;
        color: $black;

        .title {
            display: block;
            font-size: 16px;
            text-transform: uppercase;
        }
    }

    span {
        font-size: 14px;
        display: block;
    }

    .rating {
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 700;
        color: $primary;
    }

    .new {
        margin-top: -15px;
        text-transform: uppercase;
        font-weight: 600;
    }

    .concerns {
        margin-top: 20px;
    }

    .icon-group {
        display: flex;
    }

    .icon-lockup {
        margin: 0 5px;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .caption {
            font-size: 10px;
        }
    }

    .icon {
        display: inline-block;
        margin: 10px 7px;
    }

    .stars .icon {
        margin: 5px;
        color: $primary;
    }

    &.inline {
        padding: 10px 30px;

        .current-party {
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                display: inline-block;
            }
        }

        .concerns {
            margin-top: 10px;
        }

        .new {
            margin-top: 5px;
        }
    }
}


.national-grid {
    @include tablet {
        display: none;
    }

    h3 {
        margin: 10px 0 0;
    }

    .electorates {
        display: flex;
        flex-wrap: wrap;
    }

    .electorate {
        width: 26px;
        height: 26px;
        margin: 0 1px 1px 0;
        border-radius: 15px;
        padding: 1px;
        background: $primary content-box;
        border: 2px solid transparent;
        cursor: pointer;

        &.new {
            box-shadow: inset 0 0 0px 2px $primary;
            background: white !important;
        }
    }
}

.toggle-map {
    display: flex;
    width: 200px;
    margin: 10px auto;
    cursor: pointer;

    .label {
        font-weight: 700;
    }

    .toggle-electorate-view {
        width: 40px;
        padding: 2px;
        height: 20px;
        border: 1px solid $primary;
        border-radius: 10px;
        background: white;
        margin: 0 10px;
        pointer-events: none;

        span {
            width: 14px;
            height: 14px;
            background: $primary;
            border-radius: 50%;
            display: block;
            pointer-events: none;
            transition: $transition;
        }

    }

    &.active span {
        margin-left: 20px;
    }
}

svg .svg-electorate {
    //stroke-width: 2;
    //r: 9;
    transition: $transition;

    &.new {
        stroke: $primary;
        stroke-width: 2;
        fill: white;
        r: 9;
    }

    &.align-9,
    &.align-10 {
        fill: $primary;
    }

    &.align-7,
    &.align-8 {
        fill: $teal-2;
    }

    &.align-5,
    &.align-6 {
        fill: $teal-3;
    }

    &.align-3,
    &.align-4 {
        fill: $teal-4;
    }

    &.align-0,
    &.align-1,
    &.align-2 {
        fill: $teal-5;
    }
}

svg.party-on .svg-electorate {
    &.labor {
        fill: $party-labor;
    }

    &.liberal {
        fill: $party-liberal;
    }

    &.nationals {
        fill: $party-nationals;
    }

    &.independent {
        fill: $party-independents;
    }

    &.kap {
        fill: $party-kap;
    }

    &.greens {
        fill: $party-greens;
    }

    &.ca {
        fill: $party-ca;
    }
}

.national-grid {
    .electorate {
        transition: $transition;
        &.active {
            border-color: $primary;
        }
    }
    .align-9,
    .align-10 {
        background-color: $primary;
    }

    .align-7,
    .align-8 {
        background-color: $teal-2;
    }

    .align-5,
    .align-6 {
        background-color: $teal-3;
    }

    .align-3,
    .align-4 {
        background-color: $teal-4;
    }

    .align-0,
    .align-1,
    .align-2 {
        background-color: $teal-5;
    }

    &.party-on {
        .labor {
            background-color: $party-labor;
        }

        .liberal {
            background-color: $party-liberal;
        }

        .nationals {
            background-color: $party-nationals;
        }

        .independent {
            background-color: $party-independents;
        }

        .kap {
            background-color: $party-kap;
        }

        .greens {
            background-color: $party-greens;
        }

        .ca {
            background-color: $party-ca;
        }
    }
}

.legend {
    padding: 20px 0;
    width: 280px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .swatch {
        width: 16px;
        height: 16px;
        background: $primary;
        border-radius: 8px;
        margin: 0 10px 0 20px;
        &.purple {
            background: $secondary;
        }
    }
    .label {
        font-weight: bold;
        pointer-events: none;
    }
}