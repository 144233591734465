.page-top {
	height: 25vh;

	.avatar {
		height: 45vh;
		top: 5vh;
	}
}

.invert {
	color: white;
	background: $primary;

	.content {
		background: transparent;

		a {
			color: white;
		}
	}

	.menu-btn {
		color: white;
	}

}

.content {
	padding: 30px 20px;
	background: white;

	a {
		color: $primary;
		font-weight: 700;
		word-break: break-word;
	}

	img {
		width: 100%;
	}

	&.halved+.halved {
		padding-top: 0;
	}

	@include tablet {
		padding: 40px 20px;
	}

	@include desktop {
		padding: 70px 20px;

		// &.halved {
		// 	padding: 0px 20px;
		// }
	}

	@include large-desktop {
		padding: 70px 0;

		&.halved {
			padding: 50px 0;
		}
	}

	.inner {
		max-width: $mw-med;
		margin: 0 auto;

		.about &,
		.change-conversation & {
			max-width: $mw-thin;
		}
	}

	canvas {
		@include tablet {
			margin-bottom: 40px;
		}
	}

	&.centred {
		@include tablet {
			text-align: center;
		}

		.inner {
			max-width: $mw-thin;
		}
	}

	&.grey {
		background: $chat-grey;
		color: $black;

		p {
			.invert & {
				color: $dark-grey;
			}
		}
	}

	&.halved {

		.inner {
			display: flex;
			flex-direction: column;

			@include tablet {
				flex-direction: row;
			}
		}

		.copy {
			justify-content: center;
			flex-direction: column;
			display: flex;
			order: 1;

			@include tablet {
				order: 0;

				&:first-child {
					padding-right: 7%;
				}

				&:last-child {
					padding-left: 7%;
				}
			}
		}
	}

	&.top {
		padding-top: 0;
	}

	h1 {
		font-size: 24px;
		font-weight: 600;
		margin-top: 0;

		@include tablet {
			font-size: 40px;
		}
	}

	h2 {
		margin: 20px 0 0;
		font-weight: 600;
		font-size: 22px;

		@include tablet {
			font-size: 34px;
			margin-top: 0;
			margin-bottom: 20px;
		}
	}

	h3 {
		font-size: 18px;
		font-weight: 400;
		line-height: 28px;
		color: $dark-grey;

		.invert & {
			color: white;
		}

		@include tablet {
			font-size: 20px;
			max-width: 710px;
			margin: 20px auto;
		}
	}

	p {
		font-size: 16px;
		line-height: 28px;
		letter-spacing: 0.02em;
		font-weight: 500;
		color: $dark-grey;

		.invert & {
			color: white;
		}
	}

	ul {
		padding-left: 0;
	}

	li {
		font-size: 16px;
		line-height: 1.4;
		letter-spacing: .02em;
		//font-weight: 600;
	}

	.w40 img {
		@include mobile-only {
			max-width: 70%;
			margin: auto;
			display: block;
		}
	}

	@include tablet {
		.w20 {
			width: 20%;
		}

		.w40 {
			width: 40%;
			margin: auto;
		}

		.w60 {
			width: 60%;
		}

		.w80 {
			width: 80%;
		}
	}

	.img-wrap {
		display: flex;
		width: 70%;
		align-items: center;
		justify-content: center;
		padding: 5%;
		margin: auto;

		@include tablet {
			width: 100%;
			padding: 15%;
			border: 1px solid $light-grey;
			border-radius: 50%;
		}

		&:after {
			display: block;
			padding-bottom: 100%;

			@include tablet {
				content: "";
			}
		}
	}

	.boxes {
		display: flex;
		list-style: none;
		flex-direction: column;

		@include tablet {
			flex-direction: row;
		}

		li {
			padding: 15px 20px 20px;
			margin: 10px;
			border-radius: $br;
			background: $chat-grey;
			border: 1px solid $light-grey;
			text-align: center;

			@include tablet {
				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		strong {
			display: block;
			text-align: center;
			text-transform: uppercase;
			font-weight: 500;
			margin-bottom: 5px;
		}
	}

	.social-pics {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		img {
			width: calc(33% - 10px);
			margin-top: 20px;
		}
	}

	.btn {
		text-transform: uppercase;

		&.rate {
			margin-top: 0;
		}

		.icon {
			vertical-align: -6px;
			margin-right: 10px;
		}
	}

	.cta {
		margin: 20px 10px 0 0;
		display: inline-block;
		box-sizing: content-box;
		height: auto;
		padding: 18px 35px;
		font-weight: 700;
		word-break: break-word;
	}

	.mw500 {
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
	}

	input[type="email"] {
		border-radius: $br;
		border: 1px solid $light-grey;
		padding: 12px 20px 11px;
		width: 100%;
		margin-bottom: 10px;

		@include tablet {
			width: 250px;
		}
	}

	.cta-wrap {
		@include mobile-only {
			text-align: center;
		}
	}
}


.content.partners {
	h2 {
		text-align: center;
		margin: 60px 0;
	}

	.inner {
		margin-bottom: 30px;
	}

	h3 {
		text-align: left;
		margin: 0 0 10px;
		font-weight: bold;
	}
}

.content.two-datasets {
	p {
		text-align: left;
	}
}

footer {
	background: $chat-grey;
	padding: 10px 0;

	.inner {
		max-width: $mw-med;
		padding: 0 20px;
		margin: 0 auto;
		font-size: 12px;
	}

	p {
		margin: 0;
		color: $black;
	}
	a {
		color: $primary;
	}
}

body.terms {
	background: white;
}

