.parties, .current-party {
    .logo {
        background-size: contain;
        background-repeat: no-repeat;
        height: 26px;
        width: 57px;
        display: block;
        background-position: center;
        text-align: center;

        @include tablet {
            height: 20px;
        }

        &.primary {
            width: 160px !important;
            height: 160px;
            font-size: 18px;
            padding-top: 50px;
            background-color: white;
            border-radius: 50%;
            border: 1px solid $light-grey;
			background-size: 60%;
            margin: 0 auto;
            
           
			
			@include tablet {
				width: 250px !important;
				height: 250px;
				font-size: 26px;
				padding-top: 90px;
            }
            @include desktop {
				width: 350px !important;
				height: 350px;
				font-size: 36px;
				padding-top: 130px;
			}

        }

        &.labor {
            background-image: url(../img/parties/labor.png);
            width: 80px;
            @include tablet {
                width: 57px;
            }

        }

        &.liberal {
            background-image: url(../img/parties/liberal.png);
            width: 43px;
            @include tablet {
                width: 25px;
            }
        }

        &.greens {
            background-image: url(../img/parties/greens.png);
            width: 42px;
            @include tablet {
                width: 23px;
            }
        }

        &.nationals {
            background-image: url(../img/parties/nationals.png)
        }

        &.conservatives {
            background-image: url(../img/parties/conservatives.png);
            width: 100px;
        }

        &.phon {
            background-image: url(../img/parties/phon.png);
            width: 36px;
        }

        &.ca {
            background-image: url(../img/parties/centre-alliance.png);
        }

        &.coalition {
            background-image: url(../img/parties/coalition.png);
            width: 40px;
        }
        &.kap {
            background-image: url(../img/parties/kap.png);
        }
        &.andrew_wilkie {
            background: $avatar-bg;
            &:after {
                content: 'Andrew Wilkie, \A Independent';
                white-space: pre-wrap;
                top: 4px;
                position: relative;
                font-weight: 600;
                .inline.popup & {
                    content: 'Andrew Wilkie';
                }
            }
        }
        &.cathy_mcgowan{
            background: $avatar-bg;
            &:after {
                content: 'Cathy McGowan, \A Independent';
                white-space: pre-wrap;
                top: 4px;
                position: relative;
                font-weight: 600;
                .inline.popup & {
                    content: 'Cathy McGowan';
                }
            }
        }
        &.kerryn_phelps{
            background: $avatar-bg;
            &:after {
                content: 'Kerryn Phelps, \A Independent';
                white-space: pre-wrap;
                top: 4px;
                position: relative;
                font-weight: 600;
                .inline.popup & {
                    content: 'Kerryn Phelps';
                }
            }
        }
        &.julia_banks{
            background: $avatar-bg;
            &:after {
                content: 'Julia Banks, \A Independent';
                white-space: pre-wrap;
                top: 4px;
                position: relative;
                font-weight: 600;
                .inline.popup & {
                    content: 'Julia Banks';
                }
            }
        }
    }
    .selected .logo {
        background-color: transparent;
    }
    .party-tab .andrew_wilkie:after {
        content: 'A Wilkie';
    }
}
.current-party .logo {
    margin: 10px auto 20px;
    height: 50px;
    width: 220px !important;
}

.popup .current-party .logo {
    @include mobile-only {    
        width: 120px !important;
        display: inline-block;
        margin:  0 0 0 10px;
        height: 30px;
    }
}