$offset: 20px;

.avatar-wrap {
	position: absolute;
	top: $offset;
	left: 0;
	right: 0;
	z-index: -1;
	// height: calc(100vh - 110px);
	// transition: $transition;
	// transition-delay: 2s;
	width: 100%;
	opacity: 1;
	

	@include mobile-only {
		top: auto;
		bottom: 200px;
		transition: $transition-chat;
		transition-delay: .3s;
	}

	.avatar {
		transition: $transition-chat;
		transition-delay: .1s;
	}

	&.half {
		//height: calc(50vh - 50px);
		//	top: 50px;
		//	height: calc(100vh - 50px);
		//width: 80%;
		@include mobile-only {
			bottom: 50vh;
		}
		

		.avatar {
			@include mobile-only {
				height: 80vw;
				width: 83vw;
			}
			@include tablet {
				height: 50vh;
				width: calc(50vh + 3px);
			}

		}
	}

	&.hide {
		.avatar {
			opacity: 0;
			
		}
	}
}

.three-quarter .avatar-wrap.half {
	@include mobile-only {
		bottom: 75vh;
		transition-delay: 0;
	}
	
	.avatar {
		height: 40vw;
		width: 43vw;
		
	
		@include tablet {
			height: 25vh;
			width: 25vh;
		}
	}
}

.avatar {
	background: url(../img/candidate.png) no-repeat center / cover;
	height: calc(100vh - #{$offset});
	width: calc(100vh - #{$offset});
	
	margin: 0 auto;

	@include mobile-only {
		height: 100vw;
		width: 100vw;
	}

	&.user {
		background-image: url(../img/user-concerns.png);
	}
}

.static .avatar {
	@include tablet {
		background-size: contain;
	}
}

//compare page avatar
.compare-avatar-wrap {

	.avatar {
		height: 160px;
		width: 160px;
		border: 1px solid $light-grey;
		border-radius: 50%;
		background-size: cover;
		margin: auto;
		background-color: $avatar-bg;

		@include tablet {
			height: 250px;
			width: 250px;
		}

		@include desktop {
			height: 350px;
			width: 350px;
		}
	}
}


.avatar.spliced {
	background: none;
	display: flex;
	justify-content: center;
	position: relative;

	.layer {
		position: absolute;
		display: flex;
		justify-content: center;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		img {
			max-height: 100%;
			display: block;
			opacity: 0;
			
		}
	}
}


$animation-length-first: 2s;
$animation-delay-first: .2s;
$animation-length: 21.8s;
$animation-delay: .2s;

@for $i from 1 through 5 {
	@keyframes splice#{$i} {
		0% 				{background-image: url(../img/avatar/1-#{$i}.png)}
		7.978723404% 	{background-image: url(../img/avatar/1-#{$i}.png)}
		9.042553191% 	{background-image: url(../img/avatar/2-#{$i}.png)}
		10.63829787% 	{background-image: url(../img/avatar/2-#{$i}.png)}
		11.70212766% 	{background-image: url(../img/avatar/3-#{$i}.png)}
		13.29787234% 	{background-image: url(../img/avatar/3-#{$i}.png)}
		14.36170213% 	{background-image: url(../img/avatar/4-#{$i}.png)}
		15.95744681% 	{background-image: url(../img/avatar/4-#{$i}.png)}
		17.0212766% 	{background-image: url(../img/avatar/5-#{$i}.png)}
			
		32.9787234% 	{background-image: url(../img/avatar/5-#{$i}.png)}
		34.04255319% 	{background-image: url(../img/avatar/2-#{$i}.png)}
		35.63829787% 	{background-image: url(../img/avatar/2-#{$i}.png)}
		36.70212766% 	{background-image: url(../img/avatar/4-#{$i}.png)}
		38.29787234% 	{background-image: url(../img/avatar/4-#{$i}.png)}
		39.36170213% 	{background-image: url(../img/avatar/1-#{$i}.png)}
		40.95744681% 	{background-image: url(../img/avatar/1-#{$i}.png)}
		42.0212766% 	{background-image: url(../img/avatar/3-#{$i}.png)}
			
		57.9787234% 	{background-image: url(../img/avatar/3-#{$i}.png)}
		59.04255319% 	{background-image: url(../img/avatar/5-#{$i}.png)}
		60.63829787% 	{background-image: url(../img/avatar/5-#{$i}.png)}
		61.70212766% 	{background-image: url(../img/avatar/4-#{$i}.png)}
		63.29787234% 	{background-image: url(../img/avatar/4-#{$i}.png)}
		64.36170213% 	{background-image: url(../img/avatar/1-#{$i}.png)}
		65.95744681% 	{background-image: url(../img/avatar/1-#{$i}.png)}
		67.0212766% 	{background-image: url(../img/avatar/2-#{$i}.png)}
			
		82.9787234% 	{background-image: url(../img/avatar/2-#{$i}.png)}
		84.04255319% 	{background-image: url(../img/avatar/5-#{$i}.png)}
		85.63829787% 	{background-image: url(../img/avatar/5-#{$i}.png)}
		86.70212766% 	{background-image: url(../img/avatar/3-#{$i}.png)}
		88.29787234% 	{background-image: url(../img/avatar/3-#{$i}.png)}
		89.36170213% 	{background-image: url(../img/avatar/1-#{$i}.png)}
		90.95744681% 	{background-image: url(../img/avatar/1-#{$i}.png)}
		92.0212766% 	{background-image: url(../img/avatar/4-#{$i}.png)}
		100% 			{background-image: url(../img/avatar/4-#{$i}.png)}
	}
}

@keyframes lateFade {
	from  { opacity: 0;}
	10% {
		opacity: 1;
	}
	to { opacity: 1;}
}

.avatar.spliced {
	.animate-background {
		margin: auto;
		div {
			background: url(../img/avatar/1-1.png) no-repeat bottom center / contain;
			flex-grow: 1;
		//	opacity: 0;
			transition: .2s ease-in-out;
			transform-style: preserve-3d;
			backface-visibility: hidden;
			will-change: transform, opacity, background;
			//	animation: name duration timing-function delay infinite alternate-reverse forwards;
			animation: lateFade $animation-length-first ease-out both;
		}
	
		.splice-1 {
			background-image: url(../img/avatar/1-1.png);
			flex-grow: 7;
			background-position: bottom right;
			margin-right: -1px;
		}
	
		.splice-2 {
			background-image: url(../img/avatar/1-2.png);
			animation-delay: $animation-delay * 3;
			margin-right: -1px;
		}
	
		.splice-3 {
			background-image: url(../img/avatar/1-3.png);
			animation-delay: $animation-delay * 5;
			
		}
	
		.splice-4 {
			background-image: url(../img/avatar/1-4.png);
			animation-delay: $animation-delay * 2;
			margin-left: -1px;
		}
	
		.splice-5 {
			background-image: url(../img/avatar/1-5.png);;
			flex-grow: 7;
			background-position: bottom left;
			animation-delay: $animation-delay * 4;
			margin-left: -1px;
		}
	}
}

.avatar.spliced.animating {

	.animate-background {
		div {
			animation-direction: alternate;
			animation-iteration-count: infinite;
			animation-duration: $animation-length;
			animation-timing-function: cubic-bezier(0.725, 0.005, 0.325, 1.005);
			opacity: 1;
		}
		.splice-1 { animation-name: splice1; animation-delay: $animation-delay * 4 + .5; }
		.splice-2 { animation-name: splice2; animation-delay: $animation-delay * 2 + .5; }
		.splice-3 { animation-name: splice3; animation-delay: $animation-delay * 5 + .5; }
		.splice-4 { animation-name: splice4; animation-delay: $animation-delay * 1 + .5; }
		.splice-5 { animation-name: splice5; animation-delay: $animation-delay * 3 + .5;}
	}
}

.preloader {
	position: absolute;
   	overflow: hidden;
   	left: -9999px; 
   	top: -9999px;
   	height: 1px;
   	width: 1px;
}