
.nav-drawer {
	
	position: fixed;
	z-index: 9999;
	top: 0;
	bottom: 0;
	background: $primary-dark;
	width: 100%;
	right: -800px;
	transition: all .3s ease;
	max-width: 100%;
	display: flex;
    align-items: center;
    justify-content: center;

	@include tablet {
		width: 800px;
	}

	&.expanded {
		right: 0;
	}
	ul {
		width: 100%;
		padding: 0px 30px 0;
		@include tablet {
			padding: 0;
		}
		
	}

	a {
		color: white;
		text-decoration: none;
		display: block;
		padding: 10px 0;

		@include taller-height {
			padding: 20px 0;
		}

		&:hover {
			background: $primary;
		}
	}

	li {
		list-style-type: none;
		text-align: center;
		font-weight: 600;
	}

	.small {
		font-size: 18px;
		line-height: 1;
		display: block;

		&.powered {
			font-weight: 500;
			font-size: 14px;
		}
	}

	.large {
		font-size: 26px;
		line-height: 30px;
		padding-top: 5px;
		@include taller-height {
			font-size: 28px;
		}
	}
	.close-btn {
		color: white;
		font-size: 60px;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
		line-height: 22px;
		height: 50px;
		outline: none;
		padding: 15px;

		@include taller-height {
			top: 50px;
			right: 50px;
			line-height: 30px;
			height: 65px;
		}
	}
}

.af-logo {
	height: 80px;
	margin-top: 10px;
}
.auth {
	position: absolute;
    bottom: 10px;
    font-size: 11px;
	color: white;
	padding: 0 20px;
    text-align: center;
}

.nav-drawer a.terms-link {
	display: inline;
	text-decoration: underline;

	&:hover {
		background: transparent;
	}
}