@keyframes drawer {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0);
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeInOut {
	0% { opacity: 0; }
	20% { opacity: 1; }
	80% { opacity: 1; }
	100% { opacity: 0; }
}
@keyframes grow {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes blink {
	50% {
		opacity: 1;
		transform: scale(1.1);
	}
}

@keyframes bulge {
	50% {
		transform: scale(1.1);
	}
}

@keyframes appearHoz {
	0% {
		//transform: scale(.05 .05);
		transform: scale(1 0);
	}

	100% {
		transform: scale(1);
	}
}
@keyframes appearVert {
	0% {
		//transform: scale(.05 .05);
		transform: scale(0 1);
	}

	100% {
		transform: scale(1);
	}
}