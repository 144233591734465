@mixin tablet {
	@media (min-width: #{$tablet-width}) {
		@content;
	}
};

@mixin tablet-only {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
};
@mixin tablet-landscape {
	@media (min-width: #{$tablet-width}) and (max-width: #{$tablet-landscape-width}) and (orientation : landscape) {
		@content;
	}
};
@mixin mobile-only {
	@media (max-width: $tablet-width - 1)  {
		@content;
	}
};

@mixin taller-height {
	@media (min-height: $tablet-width + 1)  {
		@content;
	}
};

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
};

@mixin large-desktop {
	@media (min-width: #{$large-desktop-width}) {
		@content;
	}
};

@keyframes slideDown {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes slideUp {
	0% {
		transform: translateY(130%);
	}
	100% {
		transform: translateY(0);
	}
}