.header {
	max-width: 1440px;
	margin: 0 auto;
	padding: 20px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	position: relative;
	
	.chatbot & {
		transform: translateY(-100%);
		animation: 0.5s 1s slideDown forwards;
	}

	@include tablet {
		padding: 40px 40px 0;
	}
	
	@include large-desktop {
		padding: 40px 0 0;
	}

	.logo img {
		height: 70px;
		width: 100px;
		@include tablet {
			width: 140px;
			height: auto;
		}
	}
	// svg {
	// 	max-width: 100%;
	// }
}

.menu-btn {
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
	outline: none;
	cursor: pointer;
	height: 60px;
	padding: 15px;

	@include tablet {
		padding: 14px 35px;
	}
}