.dropdown-wrapper {
    /* Size and position */
    position: relative;
    /* Enable absolute positioning for children and pseudo elements */
    width: 500px;
    padding: 20px 30px;
    margin: 40px auto 0;

    /* Styles */
    background: white;
    outline: none;
    cursor: pointer;

    /* Font settings */
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: $br;
    border: 1px solid $light-grey;
    letter-spacing: 0.05em;

    .charts & {
        width: auto;
        font-size: 12px;
        @include tablet {
            width: 500px;
            font-size: 15px;
        }
    }

    &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -3px;
        border-style: solid;
        border-color: $light-grey transparent;
        border-width: 6px 6px 0 6px;
    }

    .dropdown {
        /* Size & position */
        position: absolute;
        top: 100%;
        left: 0;
        /* Size */
        right: 0;
        /* Size */
        margin: 0 -1px;

        /* Styles */
        background: #fff;
        border: 1px solid $light-grey;
        border-radius: 0 0 $br $br;
        font-weight: normal;
        /* Overwrites previous font-weight: bold; */

        /* Hiding */
        opacity: 0;
        pointer-events: none;
        padding: 5px 0;

        li {
            list-style: none;
            font-size: 14px;
            display: block;
            text-decoration: none;
            padding: 2px 10px 0;
            cursor: pointer;
            text-transform: none;

            .charts & {
                padding: 5px 10px;
                @include tablet {
              //      padding: 2px 10px 0;
                }
            }

            &:hover {
                background: $chat-grey;
            }
        }

    }

    &.active {
        background: $chat-grey;
        border-radius: $br $br 0 0;

        &:after {
            border-color: $light-grey transparent;
            border-width: 6px 6px 0 6px;
            margin-top: -3px;
        }

        .dropdown {
            opacity: 1;
            pointer-events: auto;
        }
    }
}