@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?ozusjz');
    src: url('../fonts/icomoon.eot?ozusjz#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?ozusjz') format('truetype'),
        url('../fonts/icomoon.woff?ozusjz') format('woff'),
        url('../fonts/icomoon.svg?ozusjz#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 26px;
    text-align: center;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon_facebook:before {
    content: "\e91c";
  }
  .icon_chat:before {
    content: "\e900";
  }
  .icon_star_empty:before {
    content: "\e901";
  }
  .icon_star_full:before {
    content: "\e902";
  }
  .icon_business:before {
    content: "\e915";
  }
  .icon_ageing_pop:before {
    content: "\e903";
  }
  .icon_call:before {
    content: "\e904";
  }
  .icon_climate:before {
    content: "\e905";
  }
  .icon_crime:before {
    content: "\e906";
  }
  .icon_defence:before {
    content: "\e907";
  }
  .icon_economy:before {
    content: "\e909";
  }
  .icon_education:before {
    content: "\e90a";
  }
  .icon_fair_work:before {
    content: "\e90b";
  }
  .icon_families:before {
    content: "\e90c";
  }
  .icon_health:before {
    content: "\e90d";
  }
  .icon_honest_gov:before {
    content: "\e90e";
  }
  .icon_illegal_migrants:before {
    content: "\e90f";
  }
  .icon_interest_rates:before {
    content: "\e910";
  }
  .icon_living_costs:before {
    content: "\e911";
  }
  .icon_mail:before {
    content: "\e912";
  }
  .icon_outside_cities:before {
    content: "\e913";
  }
  .icon_pop_growth:before {
    content: "\e914";
  }
  .icon_share:before {
    content: "\e916";
  }
  .icon_taxes:before {
    content: "\e917";
  }
  .icon_tweet:before {
    content: "\e918";
  }
  .icon_unemployment:before {
    content: "\e919";
  }
  .icon_different:before {
    content: "\e908";
  }
  .icon_is:before {
    content: "\e91a";
  }
  .icon_somewhat:before {
    content: "\e91b";
  }
  