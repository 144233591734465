.issues-container {
	width: 100%;
	margin: 0;
	dt {
		background: $chat-grey;
		border: 3px solid $chat-grey;
		border-bottom: 1px solid white;
		padding: 10px 15px;
		font-size: 14px;
		color: $primary;
		font-weight: 600;
		transition: background-color 0.2s ease-out;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include tablet {
			padding: 13px 25px 16px;
			display: block;
		}
		span {
			width: 100%;
			padding-right: 10px;
			pointer-events: none;
		}
		
		cursor: pointer;
		&:first-child {
			border-radius: $br $br 0 0;
			border-top: 3px solid $chat-grey;
		}
		&:nth-last-child(2) {
			border-radius: 0 0 $br $br;
	//		border-bottom-width: 1px;
			&.open {
				border-radius: 0;

				+ dd {
					border-bottom: 3px solid $chat-grey;
					border-radius: 0 0 $br $br;
				}
			}
		}
		&.open {
			background: white;
			.arrow {
				transform: rotate(225deg);
			}
		}
		i {
			pointer-events: none;
		}
		.arrow {
			width: 10px;
			height: 10px;
			border-style: solid;
			border-color: $primary;
			border-width: 0px 2px 2px 0px;
			transform: rotate(45deg);
			float: right;
			margin-top: -8px;
			transition: transform .2s ease-out;
			@include tablet {
				margin-top: 4px;
			}
		}
		.icon {
			margin-right: 10px;
			vertical-align: -6px;
			width: 26px;
			@include tablet {
				margin-right: 20px;
			}
		}
	}
	dd {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;
		padding: 0px 30px;
		border-width: 0 3px;
		border-color: $chat-grey;
		border-style: solid;
		margin: 0;
		
		p {
			padding: 10px 0;
		}
	}
}