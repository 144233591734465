.compare {
	header {
		position: relative;
		z-index: 1;
		//transition: 1s ease-in-out;
	}

	.electorate-heading {
		margin-top: 0px;
		text-align: center;
		font-size: 26px;
		font-weight: 700;
		margin-bottom: 0;
		padding: 0 20px;

		@include tablet {
			font-size: 32px;
		}

		span {
			color: $primary;
		}
	}
	.electorate-information {
		margin: 10px auto;
		text-align: center;
		max-width: 400px;
		padding: 0 40px;
		font-size: 14px;
		@include desktop {
			padding: 0 20px;
			font-size: 16px;
			margin-top: 20px;
			margin-bottom: 50px;
		}
		strong {
			color: $primary;
		}
	}

	.header-row {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding: 0 10px;
		

		@include tablet {
			flex-wrap: nowrap;
			padding: 0 40px
		}

		& > div {
		//	width: 100%;
			flex-basis: 50%;
			@include tablet {
				flex-basis: auto;
			}
		}
		> .score {
			order: -1;
			flex-basis: 270px;
			margin-bottom: 20px;
			display: block;
			opacity: 1;
			transition: $transition;

			@include tablet {
				flex-basis: auto;
				order: 0;
				margin-bottom: 0;
			}

			&.hide {
				opacity: 0;
				@include mobile-only {
					height: 0;
				}
			}
		}
	}
	

	.lockup {
		text-align: center;
		width: 100%;
		margin: 20px 0 0;

		@include desktop {
		//	width: 400px;
			margin: 90px auto 0;
		}

		.headline {
			font-size: 24px;
			line-height: 20px;
			font-weight: 700;
			letter-spacing: 0.025em;
			color: $primary;
			text-transform: uppercase;

			@include desktop {
				font-size: 44px;
				line-height: 40px;
			}

			span {
				display: block;
			}
		}

		.stars {
			margin: 15px 0;
			height: 25px;
			position: relative;
			
			.stars-bg, .stars-score {
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.stars-bg {
				position: absolute;
				left: 0;
				right: 0;
			}

			span {
				width: 25px;
				height: 25px;
				font-size: 25px;
				color: $primary;
				margin: 0 4px;
				

				&.full {
					background: $primary;
				}
			}
			.stars-score {
				span {
					//font-weight: bold;
					@for $i from 1 through 5 {
						&:nth-child(#{$i}) {
							opacity: 0;
							animation: .3s fadeIn forwards ($i * .2s);
						}
					}
				}
				&.icon_star_empty {
					opacity: 0 !important;
				}
			}
		}

		.description {
			font-size: 14px;
		}

	}

	h4 {
		font-size: 14px;
		font-weight: 500;
		padding: 0 10px;
		text-align: center;
		min-height: 34px;
		display: none;
		@include desktop {
			min-height: 0;
		}
	}

	// .avatar-spacer {
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: flex-end;
	// 	height: 242px;
	// 	@include desktop {
	// 		height: 407px;
	// 		justify-content: flex-end;
	// 	}
	// }
	// .options-panel {
	// 	height: 100px;
	// }

	

	.dropdown-wrapper {
		margin-top: 20px;
		padding: 7px 16px;
		width: auto;
		font-size: 12px;
		font-weight: 600;
		letter-spacing: 0;
		min-height: 44px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;



		@include desktop {
			min-height: 0;
		}

		@include desktop {
			padding: 10px 20px;
			font-size: 15px;
			font-weight: 700;
			letter-spacing: 0.05em;
		}


		&:after {
			@include mobile-only {
				right: 8px;
			}
		}
		.dropdown {
			padding: 0;
			li {
				padding: 10px;
				@include tablet {
					//padding: 9px 0;
				}
			}
		}
		span {
			pointer-events: none;
		}
		 > span {
			 
			 
			 @include desktop {
				 display: inline;
				 margin-top: 0;
			 }
		 }
		.party {
			display: flex;
			justify-content: center;
			cursor: pointer;

			@include tablet {
				// margin: 0 10px 10px 0;
			}

			.logo {
				margin-right: 10px;
				@include mobile-only {
					width: 90px;
				}
				display: none;
				@include desktop {
					display: block;
				}
			}
			.label {
				
				&.independent {
					display: inline-block;
    		//		margin-top: 6px;
					@include tablet {
						display: inline;
						margin-top: 0;	
					}
				}
			}

			.score {
				font-size: 11px;
				font-weight: 700;
				color: $primary;
				margin-left: 10px;
				text-transform: uppercase;
				
				letter-spacing: 0.1em;
				text-align: right;
				display: none;

				@include tablet {
					display: block;
					margin-top: 4px;
					text-align: left;
				}
			}
		}
	}

	.parties.hidden-score .score {
		display: none;
	}

	.pc-compare-options {
		> div {
			@extend .dropdown-wrapper;
			margin-top: 20px;
			padding: 10px 20px;
			text-align: center;
			&.selected {
				background: $chat-grey;
				cursor: default;
			}
			&:after {
				content: none;
			}
		}
		.option-my-concerns {
			margin-top: 10px;
			display: flex;
			align-items: center;
			justify-content: center;

			&.unrated {
				color: white;
				background: $primary;
				border-color: $primary;
			}
		}
	}
}