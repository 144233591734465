/****** page worldwide********/
//main scss file here

@import "variables";
@import "mixins";
@import "animations";

.hidden {
	display: none !important;
}
img {
	max-width: 100%;
}

//fade in pages 
.page-enter-active,
.page-leave-active {
	transition: opacity .5s;
}

.page-enter,
.page-leave-to {
	opacity: 0;
}

* {
	box-sizing: border-box;
	
}
body {
	font-family: proxima-nova, sans-serif;
	margin: 0;
	color: $black;
	background: $chat-grey;
	overflow-x: hidden;
	overflow-y: scroll;
	scroll-behavior: smooth;
	transition: background $transition-chat;
	@include desktop {
		background: linear-gradient(112deg, rgba(239,243,246,1) 0%, rgba(211,214,219,1) 100%);

		&.flat {
			background: $chat-grey;
		}
	}
	
}
input, button, dt, dd {
	font-family: proxima-nova, sans-serif;
}


.btn {
	padding: 14px 35px;
    height: 40px;
    border: none;
	border-radius: $br;
	background: transparent;
	transition: background-color .2s ease;
	text-decoration: none;
	line-height: 1;
	font-size: 14px;
	letter-spacing: 0.035em;
	cursor: pointer;

	&:hover {
		background: rgba(0,0,0,0.1)
	}
	&:active {
		background: rgba(0,0,0,0.2)
	}

	&.primary {
		background: $primary;
		color: white;
		&:hover {
			background: $primary-highlight;
		}
		&:active {
			background: $primary-dark;
		}
	}

	&.secondary {
		background: $secondary;
		color: white;
		&:hover {
			background: lighten($secondary, 10%);
		}
		&:active {
			background: darken($secondary, 10%);
		}
	}
	&.white {
		color: $primary !important;
		background: $chat-grey;
		&:hover {
			background: white;
		}
		&:active {
			background: $light-grey;
		}
	}
}
.ie-warning {
	display: none;
}

@import "header";
@import "nav";
@import "avatar";
@import "dropdown";
@import "chat";
@import "chatbubbles";
@import "dev";
@import "compare";
@import "compare-header";
@import "compare-animations";
@import "compare-tile";
@import "content-page";
@import "issues-list";
@import "rate-issues";
@import "chart";
@import "widget";
@import "ie";

@import "party-logos";
@import "icons";

