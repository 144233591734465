.rate-issues-block {
	width: 100%;
	margin: 5px 0 0;
	display: flex;
	flex-wrap: wrap;

	.single-issue {
		margin: 0 6px 6px 0;
		border-radius: $br;
		border: 1px solid $light-grey;
		width: calc(50% - 3px);
		padding: 10px 15px;
		cursor: pointer;
		position: relative;
		text-align: center;
		font-size: 14px;

		@include tablet {
			text-align: left;
			padding: 10px 50px 10px 15px;
		}
		@include desktop {
			padding: 16px 58px 10px 20px;
			
			font-size: 16px;
		}

		&:nth-child(even) {
			margin-right: 0;
		}

		i {
			display: block;
			margin-bottom: 10px;
			pointer-events: none;
			@include tablet {
				display: inline-block;
				margin-right: 20px;
				margin-bottom: 0px;
				vertical-align: -4px;
			}
		}

		.score {
			width: 20px;
			height: 20px;
			border-radius: 10px;
			border: 1px solid $light-grey;
			text-align: center;
			position: absolute;
			right: 5px;
			top: 5px;
			pointer-events: none;
			@include tablet {
				right: 20px;
				top: 15px;
			}
		}
		&.selected .score {
			border-color: $primary;
			background: $primary;
			color: white;
			font-size: 12px;
			line-height: 18px;
		}
	}
}
.demographics {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: 10px;
	@include tablet {
		flex-wrap: nowrap;
		margin-bottom: 0;
	}

	.ages, .gender {
		margin-bottom: 10px;
		@include tablet {
			margin-bottom: 0;
			margin-right: 20px;
		}
	}
	.label {
		text-transform: uppercase;
		font-size: 12px;
	}

	label {
		border: 1px solid $light-grey;
		border-right-width: 0;
		background: white;
		margin: 5px 0 0 0;
		padding: 8px 8px;
		display: inline-block;
		cursor: pointer;
		@include tablet {
			padding: 6px 10px;
		}

		&:nth-child(3) {
			border-radius: 10px 0 0 10px;
			
		}
		&:last-child {
			border-radius: 0 10px 10px 0;
			border-right-width: 1px;
		}
	}

	input {
		display: none;

		&:checked + label {
			background: $primary;
			color: white;
			border-color: $primary;
		}
	}
}