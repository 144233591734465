.compare {
	display: none;

	h2 {
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	}

	.row {
		display: flex;
		max-width: $mw;
		margin: 0 auto;
		flex-wrap: wrap;

		@include desktop {
			flex-wrap: nowrap;
		}

		.left,
		.right {
			width: 50%;
			flex-basis: 50%;
			transition: $transition-chat;
			opacity: 1;
			@include desktop {
				width: 100%;
				flex-basis: auto;
			}

			&.hide {
				opacity: 0;
			}
		}

		.middle {
			width: 50%;
			text-align: center;
			flex-basis: 100%;
			order: -1;

			@include desktop {
				flex-basis: auto;
				order: 0;
				display: block;
			}
		}
		.left {
			padding-right: 7px;
			@include tablet {
				padding-right: 20px;
			}
		}
		.right {
			padding-left: 7px;
			@include tablet {
				padding-left: 20px;	
			}
		}
	}

	.row.top {
		@include desktop {
			margin-bottom: 150px;
		}
	}

	.timeline-title {
		display: inline-block;
		background: $chat-grey;
		font-size: 16px;
		color: $primary;
		line-height: 1;
		padding: 10px 20px;
		border: 2px solid $primary;
		border-radius: $br;
		font-weight: 600;
		margin: 30px 0 15px;

		@include desktop {
			margin: 60px 0 0;
		}
	}

	.compare-section {
		padding: 0 10px;
		text-align: center;
		position: relative;
		@include desktop {
			padding: 0 40px;
		}
	}

	.comparison-header {
		margin: 20px 10px 0;
		font-size: 14px;
		font-weight: 500;
		
		@include desktop {
			margin: 60px 0 40px;
			font-size: 18px;
			font-weight: 600;
		}
	}

	h3 {
		font-weight: 600;
		font-size: 18px;
		margin: 50px 0 12px;
	}

	footer {
		margin: 0px 0 0;
		padding: 30px 20px 50px;
		text-align: center;
		

		@include desktop {
			margin: 130px 0 0;
			padding: 30px 20px 140px;
		}

		h2 {
			font-size: 26px;
			@include tablet {
				font-size: 30px;
			}
		}

		p {
			font-weight: 600;
			max-width: 600px;
    		margin: auto;
		}
	}

	.ctas {
		margin: 50px auto 0;
		text-transform: uppercase;
		font-size: 15px;
		letter-spacing: .05em;
		font-weight: 700;
		.cta {
			border: 1px solid $primary;
			background: white;
			color: $primary;

			display: block;
			margin: 0 0 20px;
			height: 48px;
			padding: 10px;
			@include tablet {
				display: inline-block;
				height: auto;
				margin: 0 15px 15px;
				padding: 20px 50px;
			}
		}

		i {

			display: inline-block;
			vertical-align: -6px;
			margin-right: 10px;
		}
	}

	.timeline {
		position: absolute;
		width: 20px;
		margin: 0 auto;
		z-index: -1;
		display: none;
		flex-direction: column;
		left: calc(50% - 10px);
		top: -120px;

		@include tablet {
			display: flex;
		}

		.start,
		.end {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border: 2px solid $primary;
		}

		.line {
			transition: 2s ease-in-out;
			margin-left: 9px;
			width: 2px;
			min-height: 0;
			background: $primary;
		}
	}
}