.compare {
	.concern-tile {
		background: white;
		border: 1px solid $light-grey;
		border-radius: $br;
		border-bottom: 0;
		position: relative;
		padding: 15px 7px 46px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 10px;
		width: 100%;
		min-height: 149px;
		justify-content: center;

		@include desktop {
			padding: 30px 80px 76px;
			flex-direction: row;
			margin-bottom: 20px;
		}

		.main-icon {
			font-size: 48px;
			
			@include desktop {
				margin-right: 20px;
			}
		}

		.number {
			position: absolute;
			top: 10px;
			left: 10px;
			font-size: 14px;
			font-weight: 600;
			color: $primary;
			background: $teal-5;
			padding-top: 5px;
			width: 28px;
			height: 28px;
			border-radius: 3px;
			text-align: center;
		}

		h3 {
			font-size: 12px;
			margin: 5px 0 0;
			@include desktop {
				font-size: 16px;
				margin: 0;
			}
		}

		.stat {
			color: white;
			background: $primary;
			border-radius: 0 0 $br $br;
			text-align: center;
			padding: 10px 15px;
			line-height: 1;
			letter-spacing: 0.05em;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			font-size: 12px;
			min-height: 32px;

			@include desktop {
				font-size: 16px;
				padding: 15px;
				min-height: 46px;
			}

			&.somewhat {
				background: $secondary;

				.score-symbol {
					border-color: $secondary;
					color: $secondary;
				}
			}

			&.different {
				background: $tertiary;

				.score-symbol {
					border-color: $tertiary;
					color: $tertiary;
				}
			}

			&.is {
				.score-symbol {
					color: $primary;
				}
			}
		}

		.score-symbol {
			width: 24px;
			height: 24px;
			border: 2px solid $primary;
			position: absolute;
			left: 3px;
			top: -11px;
			border-radius: 50%;
			background: white;
			@include desktop {
				width: 36px;
				height: 36px;
				top: -18px;
				left: 10px;
			}
			.icon {
				font-size: 12px;
				margin-top: 4px;
				margin-left: 1px;
				font-weight: 600;
				@include desktop {
					font-weight: normal;
					font-size: 22px;
					margin-top: 4px;
					margin-left: 0;
				}
			}
			.icon_is {
				@include desktop {
					font-size: 20px;
					margin-top: 6px;
				}			
			}
		}
	}

	.other {
		flex-wrap: wrap;
		display: flex;

		.concern-tile {
			flex-direction: column;

			&:nth-child(odd) {
				@include tablet {
					margin-right: 20px;
				}
			}
			
			@include tablet {
				width: calc(50% - 10px);
				padding: 30px 30px 76px;
			}

			.main-icon {
				@include tablet {
					margin: 0 0 20px;
				}
			}
		}
	}
}