//animate the compare screen
.compare {
	transition: 1s ease-in-out;
	&.hide {
		opacity: 0;
		header,
		.comparisons,
		footer {
			opacity: 0;
		}
	}
}
.compare.reveal {
	display: block;

	header {
		opacity: 0;
		animation: $transition-chat 1s fadeIn forwards;
	}
	.compare-section {
		opacity: 0;
		animation: $transition-chat 2s fadeIn forwards;
	}
	footer {
		opacity: 0;
		animation: $transition-chat 3s fadeIn forwards;
	}

	.end {
		opacity: 0;
		animation: .1s ease 3s fadeIn forwards;
	}	
}